// Entry point for the build script in your package.json
var startTime = new Date().getTime();
function onLoadEventHandler() {
  var latency = new Date().getTime() - startTime;
  console.log('Latency = ' + latency + 'ms');
}

import '@hotwired/turbo-rails';

import $ from 'jquery';

import canAutoplay from 'can-autoplay';

import Alpine from 'alpinejs';
import 'flowbite';
import gsap from 'gsap';

import { Application } from '@splinetool/runtime';
window.Alpine = Alpine;
Alpine.start();
// get other plugins:
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
console.log('app js fdsa');

console.log('hi');
document.addEventListener('turbo:load', () => {
  console.log('tubo:load');
  $('.animated-bar').delay(500).fadeOut();
  $('.preloader').delay(500).fadeOut('slow');
  $('#loader').addClass('hidden');

  let current = location.pathname;
  $('.nav-link').each(function () {
    let $this = $(this);
    let href = $this.attr('href');
    // if the current path is like this link, make it active
    if (href === current) {
      $this.addClass('bg-gradient-to-r from-dark-600 to-dark-700');
    } else {
      if (href != '/contact.html') {
        $this.removeClass('bg-gradient-to-r from-dark-600 to-dark-700');
      }
    }
    let services = [
      '/web-development.html',
      '/services-overview.html',
      '/digital-consulting.html',
      '/design-sprints.html',
      '/wechat-mini-programs.html',
      '/more-services.html',
    ];
    if (services.indexOf(current) !== -1) {
      $('.services-dropdown').addClass('bg-gradient-to-r from-dark-600 to-dark-700 text-white');
    }

    let work = ['/work.html', '/case-study.html', '/questionnaire.html'];
    if (work.indexOf(current) !== -1) {
      $('.work-dropdown').addClass('bg-gradient-to-r from-dark-600 to-dark-700 text-white');
    }

    let more = ['/about.html', '/case-study.html', '/questionnaire.html'];
    if (services.indexOf(current) !== -1 || more.indexOf(current) !== -1) {
      $('.more-tab').addClass('bg-gradient-to-r from-dark-600 to-dark-700 text-white ');
    }
  });

  var width = $(window).width();
  $(window).on('resize', function () {
    // console.log("resize")
    let mobileToTablet = width < 640 && $(window).width() >= 640;
    let tabletToMobileOrDesktop = width > 640 && width < 1024 && ($(window).width() >= 1024 || $(window).width() < 640);
    let desktopToTablet = width >= 1024 && $(window).width() < 1024;
    if (mobileToTablet || tabletToMobileOrDesktop || desktopToTablet) document.location.reload(true);
    // console.log("resize2")
  });

  if (window.matchMedia('(min-width: 768px)').matches) {
    ScrollTrigger.refresh();
    console.log('you are here lol');
    var lastScrollTop;
    let navbar = document.getElementById('nav');
    window.addEventListener('scroll', function () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        navbar.style.top = '-100px';
      } else {
        navbar.style.top = '0';
      }
      lastScrollTop = scrollTop;
    });
  } else {
    const showAnimNavSm = gsap
      .from('#mobile-nav', {
        yPercent: 100,
        paused: true,
        duration: 0.2,
      })
      .progress(1);

    ScrollTrigger.create({
      start: 'top top',
      end: 99999,
      onUpdate: (self) => {
        self.direction === -1 ? showAnimNavSm.play() : showAnimNavSm.reverse();
      },
    });
  }

  if (window.location.pathname === '/') {
    const canvas = document.getElementById('logo');
    const app = new Application(canvas);
    if (window.matchMedia('(min-width: 1280px)').matches) {
      app.load('https://prod.spline.design/G6xssONSooy5a5tl/scene.splinecode').then(() => {
        $('#logo-png-xl').delay(150).fadeOut('slow');
      });
    } else if (window.matchMedia('(min-width: 640px)').matches) {
      app.load('https://prod.spline.design/rKm9W5zdY11VxXTI/scene.splinecode').then(() => {
        $('#logo-png-md').delay(150).fadeOut('slow');
      });
    } else {
    }

    onLoadEventHandler();
  }

  if (window.location.pathname === '/contact.html') {
    $('.nav-contact').cc.addClass('bg-dark-700');
  } else {
    $('.nav-contact').removeClass('bg-dark-700').addClass('bg-fj-purple');
  }

  canAutoplay.video({ muted: true, inline: true }).then(({ result }) => {
    if (result === true) {
    } else {
      let videos = document.getElementsByClassName('video');
      let videoSubs = document.getElementsByClassName('video-sub');
      for (let i = 0; i < videos.length; i++) {
        videos[i].classList.add('hidden');
        videoSubs[i].classList.remove('hidden');
      }
    }
  });
});
